import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';

import './DoubleActionBlock.scss';
import { DoubleActionBlockProps } from './model';

const DoubleActionBlock: FC<DoubleActionBlockProps> = ({
  data: [
    {
      properties: { title, text, subtitle, reference, image, link, logo, linkAriaLabel },
    },
  ],
}) => {
  const { isTablet, isMobile } = useScreenRecognition();

  return (
    <section className="double-action-block" data-testid="DoubleActionBlock">
      <Container fluid>
        <div className="double-action-block__wrapper">
          {!isTablet && !isMobile ? (
            <div className="double-action-block__item double-action-block__item--left">
              {image[0].properties.image?.fallbackUrl ? (
                <GatsbyImage
                  objectFit="contain"
                  fluid={image[0].properties.image}
                  alt={image[0].properties.imageAlt}
                />
              ) : null}
            </div>
          ) : null}

          <div className="double-action-block__item double-action-block__item--right">
            {logo[0].properties.image?.fallbackUrl ? (
              <GatsbyImage
                className="double-action-block__logo"
                fluid={logo[0].properties.image}
                alt={logo[0].properties.imageAlt}
                objectFit="contain"
              />
            ) : null}

            <h2 className="double-action-block__title">
              {`${title} `}
              <span className="double-action-block__subtitle">{subtitle}</span>
            </h2>

            <DangerouslySetInnerHtml className="double-action-block__text" html={text} />

            {(isTablet || isMobile) && image[0].properties.image?.fallbackUrl ? (
              <GatsbyImage
                className="double-action-block__image--mobile"
                objectFit="contain"
                fluid={image[0].properties.image}
                alt={image[0].properties.imageAlt}
              />
            ) : null}

            <div className="double-action-block__cta-wrapper">
              <Button to={link[0].url} ariaLabel={linkAriaLabel} variant="buy">
                {link[0].name}
                <IconCustom icon="chevron-right" />
              </Button>
            </div>

            <DangerouslySetInnerHtml className="double-action-block__reference" html={reference} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentDoubleActionBlock on TDoubleActionBlockStructure {
    properties {
      title
      text
      subtitle
      reference
      linkAriaLabel
      link {
        name
        url
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 650, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      logo {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 424, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mobileImage {
              childImageSharp {
                fluid(maxWidth: 335, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default DoubleActionBlock;
