import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SymptomsCampaignBanner from 'components/SymptomsCampaignBanner';
import InfoSection from 'components/InfoSection';
import DoubleActionBlock from 'components/symptomsCampaign/DoubleActionBlock';

import { IPropsSymptomsCampaignPage } from './models';
import './SymptomsCampaignPage.scss';

const SymptomsCampaignPage: FC<IPropsSymptomsCampaignPage> = ({
  data: { allSymptomsCampaignPage, siteSettings, commonSettings, header, footer },
}) => {
  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    banner,
    infoSection,
    doubleActionBlock,
  } = allSymptomsCampaignPage.nodes[0];

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      className="symptoms-campaign-page"
    >
      <SymptomsCampaignBanner banner={banner[0]} />
      <InfoSection {...infoSection[0].properties} isShowMobileTitleOnTop />
      <DoubleActionBlock data={doubleActionBlock} />
    </Layout>
  );
};

export const query = graphql`
  query SymptomsCampaignPageQuery($link: String, $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    allSymptomsCampaignPage(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        banner {
          ...FragmentSymptomsCampaignBanner
        }
        infoSection {
          ...FragmentInfoSection
        }
        doubleActionBlock {
          ...FragmentDoubleActionBlock
        }
      }
    }
  }
`;

export default SymptomsCampaignPage;
